import ACTION_TYPES from '../actionTypes.json';
// import floorplansService from '../../services/floorplansService';

// const defaultRenderState = {
//   images: [],
//   activeImage: 0,
//   expandedView: false,
//   isLoaded: false,
//   fullView: false,
//   visible: true
// }

// export const floorplanRenderReducer = (renderState = defaultRenderState, { type, payload }) => {
//   if (type === ACTION_TYPES.FLOORPLAN_RENDER_ACTIONS.SET_RENDER_VIEW) {
//     return {
//       ...defaultRenderState,
//       fullView: payload.fullView,
//       visible: payload.visible
//     }
//   }
//   return renderState;
// }

const samplePlanDefault = {
  images: [],
  activeImage: 0,
  expandedView: false,
  isLoaded: false
  // suites: [],
  // suitesFiltered: [],
  // units: [],
  // floors: [],
  // gridView: true,
  // searchFilter: "",
  // expandedViewUnit: null,
  // expandedViewMode: null,
  // filtersDirty: false,
  // unitCount: 0,
  // mobileViewModeIsModel: true,
  // filters: {
  //   types: [],
  //   exposure: [],
  //   sizeMin: null,
  //   sizeMax: null,
  //   floorsMin: null,
  //   floorsMax: null,
  // }
}

// const filtered = (s, u, f, ff, ss) => { return floorplansService.getFilteredFloorplans(s, u, f, ff, ss) }

export const samplePlanReducer = (fp = samplePlanDefault, { type, payload }) => {
  // Initial fetch
  if (type === ACTION_TYPES.SAMPLEPLANS_ACTIONS.FETCH_DATA) {
    return { ...payload, isLoaded: true }
    // const { units, suites, floors, balconyViews, virtualTours, unitCount } = payload;
    // return { ...fp, units, suites, floors, balconyViews, virtualTours, unitCount, suitesFiltered: filtered(payload.suites, payload.units, payload.floors, fp.filters, fp.searchFilter) }
  }

  // Update grid/list view
  // if (type === ACTION_TYPES.FLOORPLAN_ACTIONS.TOGGLE_LIST_DISPLAY) {
  //   return { ...fp, gridView: !fp.gridView }
  // }

  // // Update search filter term
  // if (type === ACTION_TYPES.FLOORPLAN_ACTIONS.UPDATE_SEARCH) {
  //   return { ...fp, searchFilter: payload, suitesFiltered: filtered(fp.suites, fp.units, fp.floors, fp.filters, payload), filtersDirty: true }
  // }

  // // Toggle filter list
  // if (type === ACTION_TYPES.FLOORPLAN_ACTIONS.TOGGLE_FILTERS) {
  //   return { ...fp, filtersActive: payload !== undefined ? payload : !fp.filtersActive }
  // }

  // // Update invididual filters
  // if (type === ACTION_TYPES.FLOORPLAN_ACTIONS.UPDATE_FILTER) {
  //   return { ...fp, filters: payload, suitesFiltered: filtered(fp.suites, fp.units, fp.floors, payload, fp.searchFilter), filtersDirty: true }
  // }

  // // Update expanded view unit
  // if (type === ACTION_TYPES.FLOORPLAN_ACTIONS.TOGGLE_EXPANDED_VIEW) {
  //   return { ...fp, expandedViewUnit: payload, expandedViewMode: null }
  // }

  // // Update mobile view mode 
  // if (type === ACTION_TYPES.FLOORPLAN_ACTIONS.MOBILE_TOGGLE_VIEW) {
  //   return { ...fp, mobileViewModeIsModel: payload ?? !fp.mobileViewModeIsModel }
  // }

  // // Update expanded view mode
  // if (type === ACTION_TYPES.FLOORPLAN_ACTIONS.UPDATE_EXPANDED_VIEW_MODE) {
  //   return { ...fp, expandedViewMode: payload }
  // }

  // if (type === ACTION_TYPES.LOG_OUT) {
  //   return { ...fp, expandedViewUnit: null }
  // }

  return fp;
}
export default samplePlanReducer;