import React, { useEffect, useState, useCallback } from 'react';
import { PhotoAlbum } from "react-photo-album";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';

import Carousel, { Modal, ModalGateway } from "react-images";
import Bookmark from '../generic/Bookmark';
import PDFViewer from '../PDFViewer/PDFViewer';
// import Image from 'mui-image';
// import CircularProgress from '@mui/material/CircularProgress';

const Gallery = (props) => {

const [index, setIndex] = useState(0);
const [viewerIsOpen, setViewerIsOpen] = useState(false);
const [images, setImages] = useState([]);

    const openLightbox = useCallback((event, { photo, index }) => {
        setIndex(index);
        setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
        setIndex(0);
        setViewerIsOpen(false);
    }

    const renderPhoto = useCallback(
    ({ layout, layoutOptions, imageProps: { alt, style, ...restImageProps } }) => (
    <div 
        className={'photoItem'} 
        style={{
            position: 'relative',
            borderRadius: "4px",
            boxSizing: "content-box",
            alignItems: "center",
            width: style?.width,
            padding: `${layoutOptions.padding - 2}px`,
            paddingBottom: 0,
            marginBottom: 20,
            overflow: "hidden",
        }}
    >
        <div 
            className={'imageContainer'} 
            style={{zIndex: "2"}}
        >
            {/* <IconButton sx={{ color: 'rgba(255, 255, 255, 1)' }} style={{
                position: 'absolute',
                padding: '8px',
                right: '0px',
            }}>
                <Bookmark type={props.type} item={props.images[layout.index]} />
            </IconButton> */}
            {/* <img  */}
            <img
                // fit='cover'
                // duration={
                //     3000
                // }
                // easing='cubic-bezier(0.7, 0, 0.6, 1)'
                // showLoading={ false }
                // errorIcon={ true }
                // shift='null'
                // distance="100px"
                // shiftDuration={
                //     900
                // }
                // bgColor="inherit"
                aspectRatio={(16/9)}
                alt={alt} 
                style={{ 
                    ...style, 
                    width: "100%", 
                    padding: 0,
                    marginBottom: 0,
                }} {...restImageProps} 
            />
           
            <ImageListItemBar 
                style={{
                    zIndex: 2,
                    position: 'absolute',
                    bottom: '0%',
                }}
                title={restImageProps.title}
            />
        </div>

    </div>
    ));

    return (

        <React.Fragment>
            {/* {'GALLERY DATA'} */}
            {props.images.length > 0 ? (
            <>
            {props.images.length == 1 ? (
                <img 
                style={{
                    objectFit: "contain", 
                    width: '100%', 
                    height: '100%' 
                }}
                lazyload="true"
                key={props.images[0].img} 
                src={props.images[0].isPDF ? props.images[0].src : props.images[0].img } 
                alt={props.images[0].title} 
                onClick={()=> props.images[0].isPDF ? setViewerIsOpen(!viewerIsOpen) : null }
            />
            ) : (
                <PhotoAlbum 
                    width={'100%'}
                    height={'100%'}
                    renderPhoto={renderPhoto}
                    photos={props.images} 
                    layout={'masonry'}
                    // spacing={140}
                    columns= {props.c ? props.c : 3 }
                    onClick={(event, photo, index) => { setIndex(index); setViewerIsOpen(true); }}        
                    targetRowHeight={150}
                />
            )}
                <ModalGateway>
                {viewerIsOpen ?
                    <Modal onClose={closeLightbox}>
                    {props.images[index].isPDF ? 
                    <div className="all-page-container">
                        <PDFViewer pdf={props.images[index].img} />
                    </div>
                    :
                        <Carousel
                            currentIndex={index}
                            trackProps={{ infinite: props.images.length > 1 ? true : false }}
                            views={props.images.map(x => ({
                            ...x,
                            width: x.width,
                            height: x.height,
                            src: x.img,
                            caption: x.title
                            }))}
                            styles={{
                                header: base => ({
                                    ...base,
                                    
                                }),
                            }}
                        />
                    }
                    </Modal>
                : null
                }
                </ModalGateway>
            </>
            ) : null }
        </React.Fragment>
    )
}

export default Gallery;