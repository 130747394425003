import React, { useEffect } from 'react';
import { LoadBrochureData } from '../redux/actions/brochureActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";
import Gallery from './Gallery/Gallery';
import Box from '@mui/material/Box';

const Brochure = (props) => {

  useEffect(() => {
    props.LoadBrochureData();
    document.title = 'Brochure';
    document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
  }, []);

  
  return (
    <React.Fragment>
      {console.log(props.brochure)}
      {props.brochure.images.length > 0 ?
        <Gallery images={props.brochure.images} type={'renderings'}/>
      :  null
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return { 
    brochure: state.brochure 
  }
}

export default withRouter(connect(mapStateToProps, { LoadBrochureData })(Brochure));