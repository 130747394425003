import ACTION_TYPES from '../actionTypes.json';
import assetBuilder from '../../services/assetBuilder';

const defaultObject = {
  images: [],
  activeImage: 0,
  expandedView: false,
  isLoaded: false
  // id: 0,
  // status: "",
  // sort: 0,
  // title: "",
  // asset: ""
}

const viewsReducer = (current = defaultObject, { type, payload }) => {

  const clampIndex = (amenity, index) => Math.min(Math.max(index, 0), amenity.length - 1);

  // Get Data
  if (type === ACTION_TYPES.VIEWS_ACTIONS.FETCH_DATA) {
    return { ...payload, isLoaded: true }
  // if (type === ACTION_TYPES.OVERVIEW_ACTIONS_FETCH_DATA) {
  //   const { id, status, sort, title, asset } = payload;
  //   return { ...ov, id, status, sort, title, asset }
  // }
  }

  // Set Active Image
  if (type === ACTION_TYPES.VIEWS_ACTIONS.SET_ACTIVE_IMAGE) {
    const activeImage = clampIndex(current.activeGallery, payload);
    return { ...current, activeImage }
  }

  // Set Toggle Expanded View
  if (type === ACTION_TYPES.VIEWS_ACTIONS.TOGGLE_EXPANDED_VIEW) {
    const expandedView = payload ?? !current.expandedView;
    return { ...current, expandedView }
  }
  
  return current;
}

export default viewsReducer;