import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const StoryFetchData = (props) => async(dispatch) => {
  // const storyData = await apiService.getFilteredStoryData(props);
  const storyData = await apiService.getStoryData();
  console.log('storyData');
  console.log(storyData);

  const images = storyData.map(o => {

    // let w = Math.floor(o.width / 3);
    // let h = Math.floor(o.height / 3);

    return {
      id: o.id,
      alt: o.title,
      src: assetBuilder(o.asset),
      // src: o.thumbnail ? `${assetBuilder(o.thumbnail)}?fit=cover&width=${o.width}&height=${o.height}&quality=100` : `${assetBuilder(o.asset)}?fit=cover&width=${w}&height=${h}&quality=100`,
      asset: assetBuilder(o.asset),
      // isVideo: o.asset ? o.asset.endsWith('mp4') : false, 
      // isPDF: o.asset ? o.asset.endsWith('pdf') : false, 
      title: o.title,
      key: `${o.id}`,
      // width: o.width,
      // height: o.height,
      // thumb: `${assetBuilder(o.asset)}?fit=cover&width=${w}&height=${h}&quality=80`,
      loading: 'lazy',
    }
  });

  
  dispatch({
    type: ACTION_TYPES.BUILDER_STORY,
    // payload: storyData
    // payload: images
    payload: {
      images,
    //   activeImage: 0,
    //   expandedView: false,
    //   // isLoaded: true
    }
  });
}
