import ACTION_TYPES from '../actionTypes.json';
import configs from '../../configs/config.json';

const defaultObject = {
  floorplans: [],
  renderings: [],
  // media: [],
  // emailIncludes: [],
  animActive: false
}

const getKey = (email) => configs.CacheKey_Bookmarks + '_' + email;

const bookmarksReducer = (current = defaultObject, { type, payload, email }) => {
  let updatedBookmarks = { ...current };

  if (type === ACTION_TYPES.BOOKMARKS.ADD_BOOKMARK) {
    updatedBookmarks[payload.type] = updatedBookmarks[payload.type].concat(payload.items);
    updatedBookmarks.animActive = true;
  }
  // if (type === ACTION_TYPES.BOOKMARKS.CLEAR) {
  //   updatedBookmarks[payload.type] = updatedBookmarks[payload.type].filter(b => !payload.items.some(p => p.id === b.id))
  // }
  else if (type === ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARK) {
    updatedBookmarks[payload.type] = updatedBookmarks[payload.type].filter(b => !payload.items.some(p => p.id === b.id))
  }
  else if (type === ACTION_TYPES.BOOKMARKS.ADD_EMAIL_INCLUDES) {
    updatedBookmarks.emailIncludes.push(payload);
  }
  else if (type === ACTION_TYPES.BOOKMARKS.REMOVE_EMAIL_INCLUDES) {
    updatedBookmarks.emailIncludes = updatedBookmarks.emailIncludes.filter(i => i.id !== payload.id);
  }
  else if (type === ACTION_TYPES.BOOKMARKS.FETCH_FROM_CACHE) {
    const parsed = JSON.parse(window.localStorage.getItem(getKey(email)));
    if (parsed)
      updatedBookmarks = { ...parsed };
  }
  else if (type === ACTION_TYPES.BOOKMARKS.DISABLE_ANIM) {
    updatedBookmarks.animActive = false;
  }
  else if (type === ACTION_TYPES.AUTHENTICATION.LOG_OUT || type === ACTION_TYPES.AUTHENTICATION.AUTH_FAILED) {
    return { ...defaultObject };
  }
  else {
    return current;
  }

  // Cache the bookmarks
  window.localStorage.setItem(getKey(email), JSON.stringify(updatedBookmarks));
  return updatedBookmarks
}

export default bookmarksReducer;