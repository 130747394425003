import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const loadScreenSaverData = () => async(dispatch) => {
    const screensaverData = await apiService.getScreensaverData();
  
    const images = screensaverData.map(o => {
        return {
            id: o.asset,
            alt: o.subtitle,
            img: assetBuilder(o.asset),
            title: o.title,
            key: `${o.id}`,
            loading: 'lazy'
          }
    });

    dispatch({
        type: ACTION_TYPES.LOAD_SCREENSAVER_DATA,
        payload: {
            images,
            // isLoaded: true
          }
    });
}
