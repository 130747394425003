import React from 'react';
import { connect } from 'react-redux';
// import FavouritesEmailIncludes from './FavouritesEmailIncludes';
import Input from '../../../generic/Input';
import Button from '../../../generic/Button';
import apiService from '../../../../services/apiService';
import Chip from '@mui/material/Chip';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class FavouritesEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '', disclaimer: false, sendInProgress: false, progressText: '', emails: [] }
  }

  handleDelete = (e) => {
    this.setState({ emails: [...this.state.emails].filter(ee => ee !== e) })
  }

  onInputChange = (v, force) => {
    // Take the string and divide it by ,s
    // Trim each line and verify if it validates the email
    // Check if the current email list contains it, if not add it
    // Set the input to the last out of the list

    if (force)
    v = this.state.email;
    
    const lastEntry = v[v.length - 1];
    let currentEmails = [...this.state.emails];

    if (!force && lastEntry !== ',' && lastEntry !== ' ') {
      this.setState({ email: v });
      return;
    }

    let newString = '';
    const entries = v.split(',');
    for (let entry of entries) {
      let email = entry.trim()
      let isValid = this.validateEmail(email);
      console.log('entry is ', entry)
      if (email !== '' && isValid) {
        console.log(`${entry} is a valid email`)
        if (!currentEmails.includes(email)) 
          currentEmails = [...currentEmails, email];
        newString = ''
      } else {
        newString = entry;
      }
    }
    this.setState({ email: newString, emails: currentEmails });
  }

  renderChips = () => {
    return (
      <div className="fav-email-chips-container">
        {this.state.emails.map((e, i) => <Chip variant="outlined" key={i} label={e} onDelete={() => this.handleDelete(e)} />)}
      </div>
    )
  }

  renderEmailInput = () => {
    return (
      <div className="fav-email-input">
        {this.renderChips()}
        <Input
          placeholder="Enter multiple emails separated by a comma"
          value={this.state.email}
          onChange={(v) => this.onInputChange(v, false)}
          onFocusOut={(v) => this.onInputChange(v, true)}
          height="44px"
        />
      </div>
    );
  }

  renderEmailDisclaimer = () => {
    const active = this.state.disclaimer;
    return (
      <div className="fav-email-disc" ref={this.props.innerRef}>
        <input id="fav-email-disc" type='checkbox' checked={active} onChange={() => this.setState({ disclaimer: !active })} />
        <label className="fav-email-disc-text" htmlFor="fav-email-disc">
          {this.props.disclaimer}
        </label>
      </div>
    )
  }

  renderButton = () => {
    const active = this.state.disclaimer && this.state.emails.length > 0 && !this.state.sendInProgress;

    return (
      <Button
        className="fav-email-btn"
        text="Send"
        width="100px"
        disabled={!active}
        onClick={this.sendEmail}
      />
    );
  }

  sendEmail = () => {
    const email = this.state.emails.join(',');
    const data = this.props.bookmarks;
    this.setState({ progressText: 'Sending...', sendInProgress: true });
    apiService.postFavouritesList(data, email, this.onSendComplete);
  }

  onSendComplete = (status) => {
    const text = status === 200 ?
      'Your favourites have been sent!' :
      'Error occured when sending favourites...'
    this.setState({ progressText: text, sendInProgress: false });
  }

  renderStatusText = () => {
    return <p className="fav-email-text"><span>{this.state.progressText}</span></p>
  }


  validateEmail(e) {
    var re = /\S+@\S+\.\S+/;
    return re.test(e);
  }

  // render() {
  //   return (
  //     <div className="fav-email">
  //       <div className="fav-email-title">Email My Favourites</div>
  //       {/* <FavouritesEmailIncludes /> */}
  //       {this.renderEmailInput()}
  //       {this.renderEmailDisclaimer()}
  //       {this.renderButton()}
  //       {this.renderStatusText()}
  //     </div>
  //   )
  // }
  render() {
    return (
      <React.Fragment>
        <Box sx={{ m:0}} style={{display: 'flex', cursor: 'pointer'}}>
          <Checkbox style={{top: '0px', margin: '0 auto auto auto', paddingTop: 0, userSelect: 'none'}} checked={this.state.disclaimer} onChange={() => this.setState({ disclaimer: !this.state.disclaimer })}/>
          <Typography variant="caption" display="block" style={{ width: 250 }} sx={{color: '#777777', fontSize: 10}}>
            By clicking this box I expressly provide my consent to receive electronic messages from [Acrobat App and its affiliates] retroactively, today and in the future. 
            I understand that I may withdraw my consent by unsubscribing at any time.
          </Typography>
        </Box>
        <Button variant="contained" href="#contained-buttons" sx={{my:2}}>
            Send
        </Button>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => { return { bookmarks: state.bookmarks, disclaimer: state.project.favourites_disclaimer } };
const FavoritesEmailRedux = connect(mapStateToProps, null)(FavouritesEmail)

export default React.forwardRef((p, r) => <FavoritesEmailRedux innerRef={r} {...p} />);