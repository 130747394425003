import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, useParams } from "react-router-dom";

import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Carousel, { Modal, ModalGateway } from "react-images";
import IconButton from '@mui/material/IconButton';
import { useLocation } from "react-router-dom";
import Gallery from './Gallery/Gallery';

import { LoadSamplePlansData } from '../redux/actions/samplePlansActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";


const SamplePlans = (props) => {

    // const [filter, setFilter] = useState('');
    // const [filteredSamplePlans, setFilteredSamplePlans] = useState();
    // const [gotFilter, setGotFilter] = useState(false);

    let params = useParams();
    
    document.title = 'Sample Plans';
    document.documentElement.style.setProperty('--asset-background_image', 'none');

    useEffect(() => {
        // console.log('fetching with filter: '+params.id);
        // console.log(params.id);
        // setFilter(params.id);
        props.LoadSamplePlansData(params.id);
        // setCurrentImageIndex(0);
    }, [params.id]);

    return (
      <React.Fragment>
        {/* {console.log('SamplePlans')}
        {console.log(props)} */}
        {props.sampleplans && props.sampleplans.images.length > 0 ?
          <Gallery images={props.sampleplans.images} type={"floorplans"}/>
        :  null
        }
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return { sampleplans: state.sampleplans }
}

export default withRouter(connect(mapStateToProps, { LoadSamplePlansData })(SamplePlans));