import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
// import BuilderStoryTemplate from './BuilderStoryTemplate';
// import BuilderStory from '../configs/builder_story/BuilderStory';
// import configs from '../configs/config.json';
// import assetBuilder from '../services/assetBuilder';
// import Gallery from './Gallery/Gallery';
// import Image from 'mui-image';

import { StoryFetchData } from '../redux/actions/storyActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Story = (props) => {
    // const [filter, setFilter] = useState('');
    
    let params = useParams();
    // console.log('Story ! '+params.id);
    useEffect(() => {
        // console.log('fetching with filter: '+params.id);
        // setFilter(params.id);
        props.StoryFetchData(params.id);
        document.title = 'Story';
        console.log(props);
    }, []);

    return (
        <Box 
            className='story'
            sx={{ width: '100%', height: '100%'}}
        >
            {props.story && props.story.images && props.story.images.length > 0 ? (
                props.story.images.map((story, index) => {
                    if (story.id == params.id) {
                        return ( 
                            <img 
                                style={{
                                    objectFit: "contain", 
                                    width: '100%', 
                                    height: '100%' 
                                }}
                                key={story.id} 
                                src={story.asset} 
                                alt={story.title}
                            />
                        )
                    }
                })) : null
            }
        </Box>
    );
}

const mapStateToProps = (state) => {
    console.log(state);
    return { story: state.story }
  }
  // export default connect(mapStateToProps, { LoadAmenitiesData })(Amenities);
  export default withRouter(connect(mapStateToProps, { StoryFetchData })(Story));