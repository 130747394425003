import React from 'react';
import A from '../../../generic/A';
import { connect } from 'react-redux';
import { BookmarkRemove } from '../../../../redux/actions/bookmarksActions';

class FavouritesRenderingItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {remove: false};
    this.animTime = 250;
  }

  removeItem = () => {
    const { item } = this.props;
    setTimeout(() => {
      this.props.BookmarkRemove('renderings', [item]);
    }, this.animTime)
    this.setState({remove:true})
  }

  render() {
    const { item } = this.props;

    return (
      <div className={`fav-list-content-item fav-list-content-item-rnd ${this.state.remove ? 'fav-list-content-item-hide' : ''}`}>
        <div className="fav-list-content-item-container fav-list-content-item-container-rnd">
          <img height="60px" width="80px" src={item.thumbnail} alt="thumbnail" style={{ objectFit: "cover" }} />
          <div className="fv-lst-cnt-txt fv-lst-cnt-txt-bld"><div>{item.title}</div></div>
          <A className="fv-lst-cnt-txt" onClick={this.removeItem}><span className="icon-cancel" /></A>
        </div>
      </div>
    );
  }
}

export default connect(null, { BookmarkRemove })(FavouritesRenderingItem);