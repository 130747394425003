import React, { useEffect } from 'react';
import Gallery from './Gallery/Gallery';

import { LoadInvestorBrochureData } from '../redux/actions/investorBrochureActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const InvestorBrochure = (props) => {

  useEffect(() => {
    props.LoadInvestorBrochureData();
    document.title = 'Investor Brochure';
    document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
  }, []);

  return (
    <React.Fragment>
      {props.investorbrochure.images.length > 0 ?
        <Gallery images={props.investorbrochure.images} type={'renderings'}/>
      :  null
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return { 
    investorbrochure: state.investorbrochure 
  }
}

export default withRouter(connect(mapStateToProps, { LoadInvestorBrochureData })(InvestorBrochure));