import React, { useEffect } from 'react';
import Gallery from './Gallery/Gallery';

import { LoadAmenitiesData } from '../redux/actions/amenitiesActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Amenities = (props) => {

  useEffect(() => {
    props.LoadAmenitiesData();
    document.title = 'Area Amenities';
    document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
  }, []);

  return (
    <React.Fragment>
      {console.log(props)}
      {props.amenities.images.length > 0 ?
        <Gallery images={props.amenities.images} type={'renderings'}/>
      :  console.log('no images')
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return { 
    amenities: state.amenities 
  }
}

export default withRouter(connect(mapStateToProps, { LoadAmenitiesData })(Amenities));