import ACTION_TYPES from '../actionTypes.json';

const defaultObject = {
    images: [],
    isLoaded: false
}

const screensaverReducer = (current=defaultObject, {type, payload}) => {
  if (type === ACTION_TYPES.LOAD_SCREENSAVER_DATA) {
    return {...payload, isLoaded: true}
  }
  return current;
}

export default screensaverReducer;