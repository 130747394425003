import ACTION_TYPES from '../actionTypes.json';

export const BookmarkAdd = (type, items) => async (dispatch, getState) => {
  // const email = getState().authentication.email;
  setTimeout(() => dispatch({type: ACTION_TYPES.BOOKMARKS.DISABLE_ANIM}), 250);
  dispatch({
    type: ACTION_TYPES.BOOKMARKS.ADD_BOOKMARK,
    payload: { type, items },
    // email
  });
}

export const BookmarkRemove = (type, items) => async (dispatch, getState) => {
  // const email = getState().authentication.email;
  dispatch({
    type: ACTION_TYPES.BOOKMARKS.REMOVE_BOOKMARK,
    payload: { type, items },
    // email
  });
}

// export const BookmarkClear = (type, items) => async (dispatch, getState) => {
//   // const email = getState().authentication.email;
//   dispatch({
//     type: ACTION_TYPES.BOOKMARKS.CLEAR,
//     payload: { type, items },
//     // email
//   });
// }

export const BookmarkFetchFromCache = () => async (dispatch, getState) => {
  // const email = getState().authentication.email;
  dispatch({
    type: ACTION_TYPES.BOOKMARKS.FETCH_FROM_CACHE,
    // email
  });
}

