import React from 'react';

const Button = (props) => {
  return (
    <button 
      onClick={props.disabled ? null : props.onClick} 
      className={`btn ${props.small ? 'btn-sm' : '' } ${props.className ?? ""} ${props.disabled ? 'btn-disabled' : ''}`}
      style={{width: props.width ?? 'auto'}}
    >
      {props.text}
    </button>
  )
}

export default Button;