import React, { useEffect } from 'react';
import Gallery from './Gallery/Gallery';

import { LoadRenderingsData } from '../redux/actions/renderingsActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Renderings = (props) => {

  useEffect(() => {
    props.LoadRenderingsData();
    document.title = 'Renderings';
    document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
  }, []);

  return (
    <React.Fragment>
      {/* {console.log(props.renderings)} */}
      {props.renderings && props.renderings.images.length > 0 ?
        <Gallery images={props.renderings.images} type={'renderings'}/>
      :  console.log('no images')
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return { 
    renderings: state.renderings 
  }
}

export default withRouter(connect(mapStateToProps, { LoadRenderingsData })(Renderings));