import React from 'react';
import AnimateHeight from 'react-animate-height';
import NumberDisplay from '../../../generic/NumberDisplay';
import A from '../../../generic/A';

import FavouritesFloorplanItem from './FavouritesFloorplanItem';
import FavouritesRenderingItem from './FavouritesRenderingsItem';
import FavouritesMediaItem from './FavouritesMediaItem';


class FavouritesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: true }
  }

  toggleState = () => this.setState({ active: !this.state.active });

  renderList = (content) => {
    switch(this.props.type){
      case 'floorplans': 
        return <FavouritesFloorplanItem key={content.id} item={content} />;
      case 'renderings': 
        return <FavouritesRenderingItem key={content.id} item={content} />;
      case 'media': 
        return <FavouritesMediaItem key={content.id} item={content} />;
      default:
        return null;
    }
  }

  render() {
    const { name, content, type } = this.props;

    // TEMP
    if (!type) return null;


    if (content.length === 0)
      return null;

    return (
      <div className="fav-list">
        <A className="fav-list-title-wrapper" onClick={this.toggleState}>
          <div className="fav-list-title">
            <div className="fav-list-title-txt">
              <div className="fav-list-title-txt-nm">{name}</div>
              <NumberDisplay value={content.length} />
            </div>
            <span className={this.state.active ? 'icon-arrow-up' : 'icon-arrow-down'} />
          </div>
        </A>


        <AnimateHeight height={this.state.active ? 'auto' : '0'}>
          <div className="fav-list-content">
            {content.map(this.renderList)}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

export default FavouritesList;