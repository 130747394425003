import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

  export const LoadSamplePlansData = (props) => async (dispatch) => {
    const samplePlansData = await apiService.getFilteredSamplePlansData(props);
    console.log('filter');
    console.log(props);
    // const keyword = props.replaceAll('%20', ' ');
    // console.log('filter '+ props);
    // console.log(keyword);
    // floorplansData = floorplansData.filter.replaceAll('%20', ' ');
    // console.log('floorplansData');
    // console.log(floorplansData);
    // const test = floorplansData[0].category;
    // if (test === keyword){
    //   console.log('matched!');
    // }else{
    //   console.log('not matched! :(');
    //   console.log(floorplansData[0].category);
    //   console.log(keyword);

    // }
    
    const images = samplePlansData.filter(o => o.category === props).map(o => {

      let w = Math.floor(o.width / 3);
      let h = Math.floor(o.height / 3);

      return {
        id: o.asset,
        // tags: o.category,
        alt: o.title,
        src: o.thumbnail ? `${assetBuilder(o.thumbnail)}?fit=cover&width=${o.width}&height=${o.height}&quality=100` : `${assetBuilder(o.asset)}?fit=cover&width=${w}&height=${h}&quality=100`,
        img: assetBuilder(o.asset),
        isVideo: o.asset ? o.asset.endsWith('mp4') : false, 
        isPDF: o.asset ? o.asset.endsWith('pdf') : false, 
        // thumbnail: f.thumb ? assetBuilder(f.thumb) : assetBuilder(f.asset, 'thumb'),
        title: o.title,
        key: `${o.id}`,
        width: o.width,
        height: o.height,
        category: o.category,
        thumb: `${assetBuilder(o.image)}?fit=cover&width=${w}&height=${h}&quality=80`,
        loading: 'lazy',
        // icon: assetBuilder(f.icon),
        // photosphere: f.photosphere
      }
    });

    // console.log('images');
    // console.log(images);

  // Make API call to get the floorplans
  // const suites = await apiService.getSuites();
  // const units = await apiService.getUnits();
  // const floors = await apiService.getFloors();
  // const balconyViews = await apiService.getBalconyViews();
  // const virtualTours = await apiService.getVirtualTours();
  // const unitCount = units.filter(u => u.suite !== null).length;
  dispatch({
    type: ACTION_TYPES.SAMPLEPLANS_ACTIONS.FETCH_DATA,
    payload: {
      images,
      activeImage: 0,
      expandedView: false,
    }
    // type: ACTION_TYPES.FLOORPLAN_ACTIONS.FETCH_SUITES,
    // payload: { units, suites, floors, balconyViews, virtualTours, unitCount }
  });
}


export const SamplePlansSetActiveImage = (id) => {
  return {
    type: ACTION_TYPES.SAMPLEPLANS_ACTIONS.SET_ACTIVE_IMAGE,
    payload: id
  }
}

export const SamplePlansToggleExpandedView = (toggle) => {
  return {
    type: ACTION_TYPES.SAMPLEPLANS_ACTIONS.TOGGLE_EXPANDED_VIEW,
    payload: (toggle === true || toggle === false) ? toggle : null
  }
}


// TOGGLE LIST/GRID VIEW
// export const FloorplansToggleDisplay = () => {
//   return {
//     type: ACTION_TYPES.FLOORPLAN_ACTIONS.TOGGLE_LIST_DISPLAY
//   }
// }

// // UPDATE SEARCH
// export const FloorplansUpdateSearch = (value) => {
//   return {
//     type: ACTION_TYPES.FLOORPLAN_ACTIONS.UPDATE_SEARCH,
//     payload: value
//   }
// }

// // Toggle Filters
// export const FloorplansToggleFilterDisplay = (payload) => {
//   if (payload !== true && payload !== false) payload = undefined;
//   return {
//     type: ACTION_TYPES.FLOORPLAN_ACTIONS.TOGGLE_FILTERS,
//     payload: payload
//   }
// }

// // UPDATE FILTERS
// export const FloorplansUpdateFilters = (filters) => {
//   return {
//     type: ACTION_TYPES.FLOORPLAN_ACTIONS.UPDATE_FILTER,
//     payload: filters
//   }
// }

// // Set Expanded View Unit
// export const FloorplanSetExpandedViewUnit = (unitId) => {
//   return {
//     type: ACTION_TYPES.FLOORPLAN_ACTIONS.TOGGLE_EXPANDED_VIEW,
//     payload: unitId
//   }
// }

// // Change mobile display mode
// export const FloorplanToggleMobileDisplayMode = (isModel) => {
//   if (isModel !== true && isModel !== false)
//     isModel = undefined;
//   return {
//     type: ACTION_TYPES.FLOORPLAN_ACTIONS.MOBILE_TOGGLE_VIEW,
//     payload: isModel
//   }
// }

// // Update expanded view mode
// export const FloorplanUpdateExpandedViewMode = (target) => {
//   return {
//     type: ACTION_TYPES.FLOORPLAN_ACTIONS.UPDATE_EXPANDED_VIEW_MODE,
//     payload: target
//   }
// }