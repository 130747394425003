import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const LoadInvestorBrochureData = () => async(dispatch) => {
  const investorBochureData = await apiService.getDigitalInvestorBrochureData();
  
  const images = investorBochureData.map(o => {

    // let w = Math.floor(o.width / 3);
    // let h = Math.floor(o.height / 3);

    return {
      id: o.asset,
      alt: o.title,
      src: `${assetBuilder(o.thumbnail)}?fit=cover&width=${706}&height=${924}&quality=100`,
      img: assetBuilder(o.asset),
      isVideo: o.asset ? o.asset.endsWith('mp4') : false, 
      isPDF: o.asset ? o.asset.endsWith('pdf') : false, 
      title: o.title,
      key: `${o.id}`,
      width: 706,
      height: 924,
      // thumb: `${assetBuilder(o.asset)}?fit=cover&width=${w}&height=${h}&quality=80`,
      loading: 'lazy',
    }
  });

  dispatch({
    type: ACTION_TYPES.INVESTORBROCHURE_ACTIONS.FETCH_DATA,
    payload: {
      images,
      activeImage: 0,
      expandedView: false,
      // isLoaded: true
    }
  });
}

export const InvestorBochureSetActiveImage = (id) => {
  return {
    type: ACTION_TYPES.INVESTORBROCHURE_ACTIONS.SET_ACTIVE_IMAGE,
    payload: id
  }
}

export const InvestorBochureToggleExpandedView = (toggle) => {
  return {
    type: ACTION_TYPES.INVESTORBROCHURE_ACTIONS.TOGGLE_EXPANDED_VIEW,
    payload: (toggle === true || toggle === false) ? toggle : null
  }
}
