import React, { useState, useCallback, useEffect } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import CircularProgress from '@mui/material/CircularProgress';
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js' //cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js;
// const pdfjs = require('pdfjs-dist');
// pdfjs.PDFJS.workerSrc = '/js/pdf.worker.js'
const PDFViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    // const [isLoading, setIsloading] = useState(true);
    const [PDFBlob, setPDFBlob] = useState(null);
    console.log('PDFViewer: '+props);
    console.log(props);

    // const onDocumentLoadSuccess =  useCallback((event, { numPages }) => {

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        // isLoading = false;
    }

    // const onLoadSuccess = (pdf) => {
    //     console.log('onLoadSuccess');
    //     console.log(pdf);
    //     // setNumPages(pdf.numPages);
    //     // setPDFBlob(pdf);
    //     // setIsloading(false);
    // }

    const { pdf } = props;
    // setPDFBlob(pdf);

    // useEffect(() => {
    //     const controller = new AbortController();
    //     console.log('pdf: '+pdf);
    //     fetch(pdf, { signal: controller.signal })
    //       .then(res => res.blob())
    //       .then(file => setPDFBlob(file))
    //       .catch(e => { 
    //         if(e.name !== 'AbortError') { /* handle error*/ }
    //       });
    
    //     return () => controller.abort();
    //   }, [pdf, setPDFBlob]);

      
    return (
        <React.Fragment>
            <div 
                className="pdf-container" 
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                }}>
                    <Document
                        file={pdf}
                        // file={PDFBlob}
                        options={{ workerSrc: '/pdf.worker.js' }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page 
                                key={`page_${index + 1}`} 
                                pageNumber={index + 1} 
                                // renderAnnotationLayer={false}
                                // renderInteractiveForms={false}
                                // renderTextLayer={false}
                            />
                        ))}
                    </Document>
            </div>
        </React.Fragment>
    )
}

export default PDFViewer;