import React, {useState} from 'react';
import Badge from '@mui/material/Badge';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const FavouriteBadge = ({count}) => {
    // const [count, setCount] = useState(5);
    // const count = 24;

    return (
        <div className="favBadge" style={{padding: '4px'}}>
                {count > 0 ?
                    <Badge badgeContent={count} overlap="circular" color="primary">
                        <BookmarkIcon />
                    </Badge>
                     : 
                    <Badge overlap="circular" color="primary" >
                        <BookmarkBorderIcon />
                    </Badge>
                    
                }
        </div>
    )
}

export default FavouriteBadge;