import React from 'react';
import A from '../../../generic/A';
import { connect } from 'react-redux';
import { BookmarkRemove } from '../../../../redux/actions/bookmarksActions';

class FavouritesMediaItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {remove: false};
    this.animTime = 250;
  }

  removeItem = () => {
    const { item } = this.props;
    setTimeout(() => {
      this.props.BookmarkRemove('media', [item]);
    }, this.animTime)
    this.setState({remove:true})
  }

  render() {
    const { item } = this.props;

    return (
      <div className={`fav-list-content-item fav-list-content-item-md ${this.state.remove ? 'fav-list-content-item-hide' : ''}`}>
        <div className="fav-list-content-item-container fav-list-content-item-container-md">
          <span className="icon-pdf"/>
          <div className="fv-lst-cnt-txt fv-lst-cnt-txt-bld"><div>{item.title}</div></div>
          <A className="fv-lst-cnt-txt" onClick={this.removeItem}><span className="icon-cancel" /></A>
        </div>
      </div>
    );
  }
}

export default connect(null, { BookmarkRemove })(FavouritesMediaItem);