import { configureStore } from '@reduxjs/toolkit'

import projectReducer from './reducers/projectReducer';
import authenticateReducer from '../features/authentication/authenticationSlice';
// import overviewReducer from './reducers/overviewReducer';
import amenitiesReducer from './reducers/amenitiesReducer';
import storyReducer from './reducers/storyReducer';
// import suitesReducer from './reducers/suitesReducer';
// import floorplanReducer from './reducers/floorplanReducer';
// import neighbourhoodReducer from './reducers/neighbourhoodReducer';
import bookmarksReducer from './reducers/bookmarksReducer';
import screensaverReducer from './reducers/screensaverReducer';
import samplePlansReducer from './reducers/samplePlansReducer';
import viewsReducer from './reducers/viewsReducer';
import brochureReducer from './reducers/brochureReducer';
import investorBrochureReducer from './reducers/investorBrochureReducer';
import keyplansReducer from './reducers/keyplansReducer';
import renderingsReducer from './reducers/renderingsReducer';

export const store = configureStore({
  reducer: {
    authentication: authenticateReducer,
    project: projectReducer,
    sampleplans: samplePlansReducer,
    views: viewsReducer,
    brochure: brochureReducer,
    investorbrochure: investorBrochureReducer,
    story: storyReducer,
    renderings: renderingsReducer,
    amenities: amenitiesReducer,
    bookmarks: bookmarksReducer,
    keyplans: keyplansReducer,
    screensaver: screensaverReducer,
  }
})

export default store;

