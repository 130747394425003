import React, { useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
import Gallery from './Gallery/Gallery';

import { LoadViewsData } from '../redux/actions/viewsActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";
// import Navigation from './Navigation';

const Views = (props) => {

  let params = useParams();
  const location = useLocation();
  const id = location.pathname.match(/(\d+)/);
  
  useEffect(() => {
    props.LoadViewsData();
    console.log('path: '+location.pathname);
    document.title = 'Views';
    document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
    // Navigation(view)
  }, []);

  return (
    <React.Fragment>
      {/* {console.log('VIEWDATA: ')}
      {console.log(id[0]-1)}
      {console.log(props.views.images[id[0]-1])} */}
      {props.views.images[id[0]-1] && props.views.images[id[0]-1] && props.views.images[id[0]-1].length > 0 ? (
          <Gallery images={props.views.images[id[0]-1]} type={'renderings'} c={2} /> 
        ) : null
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return { 
    views: state.views 
  }
}

export default withRouter(connect(mapStateToProps, { LoadViewsData })(Views));