import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NumberDisplay from '../../generic/NumberDisplay';

import Fab from '@mui/material/Fab';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Favourites from './Favourites';
import FavouriteBadge from './FavouriteBadge';

const FavouritesButton = (props) => {
    const [count, setCount] = useState(0);
    // const bookmarks = props.bookmarks;
    // const shakeActive = props.bookmarks.animActive;
    // console.log('bookmarks');
    // console.log(bookmarks);
    // console.log(props);
    
    useEffect(() => {
        setCount(props.bookmarks.floorplans.length +
            props.bookmarks.renderings.length)
    }, [props.bookmarks]);

    // const count = bookmarks.floorplans.length +
    // bookmarks.renderings.length;

    // const count = 24;

    // const ToggleMenuOpen = () => {
    //     setCount(count++);
    //     console.log('open menu from button!');
    //     // props.toggleFavorites();
    // }

    // console.log(props);
    // const { floorplans, renderings, media } = props.bookmarks;

    return (
        <div className="favourites" style={{zIndex: 10, position: 'fixed', bottom: '0px', right: '0px', padding: '32px'}}>
            <Fab color="default" aria-label="add">
                {/* {count ? 
                    <Badge badgeContent={count} overlap="circular" color="primary" >
                        <BookmarkIcon />
                    </Badge>
                     : 
                    <Badge overlap="circular" color="primary" >
                        <BookmarkBorderIcon />
                    </Badge>
                    
                } */}
                <FavouriteBadge count={count}/>
            </Fab>
        </div>
    )
}

const mapStateToProps = state => { return { bookmarks: state.bookmarks } }
export default connect(mapStateToProps)(FavouritesButton);