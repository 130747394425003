import React from 'react';

const NumberDisplay = ({value}) => {
  return (
    <div className={`number-display ${value > 99 ? 'big-number' : ''}`}>
      <div className='number-display-text'>{value}</div>
    </div>
  )
}

export default NumberDisplay;