import React from 'react';

const Input = React.forwardRef((props, ref) => {
  return (
    <input
      className="std-input"
      placeholder={props.placeholder}
      value={props.value}
      onChange={e => props.onChange(e.target.value, e)}
      onBlur={() => props.onFocusOut()}
      style={
        {
          height: props.height ?? '56px',
          lineHeight: props.height ?? '56px',
          borderBottom: `1px solid ${props.borderColor ?? '#0B0B0B'}`
        }
      }
      type={props.type ?? 'text'}
    />
  );
})

export default Input;