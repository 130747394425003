import React, {useState, useEffect} from "react";
import './Screensaver.scss';
var classNames = require('classnames');

const Screensaver = (props) => {

  const IMAGE_PARTS = 4;
  const [slides, setSlides] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);


    useEffect(() => {
      setSlides(props.images.images);
      const interval = setInterval(() => {
        setSlideIndex(slide => slide + 1);
      }, 5000);
      return ()=> {
        window.clearTimeout(interval);
      }
    }, []);

    useEffect(() => {
      let slide = slideIndex;

      if (slide < 0) 
        slide = props.images.images.length - 1;
      if (slide >= props.images.images.length) 
        slide = 0;

      setSlideIndex(slide);
      // console.log('mount');
    }, [slideIndex])

    return (
      <>
        {/* {console.log('showing slide: '+slideIndex+'/'+slides.length)} */}
        {/* {console.log('active: '+props.active)} */}
        { props.active && props.images.images.length > 0 ?
          <div className="screensaver">
            <div className='slider'>
                <p className="slider__top-heading">Screensaver</p>
                    <div className="slider__slides">
                    {slides.map((slide, index) => (
                        <div
                          className={classNames('slider__slide', { 's--active': slideIndex === index })}
                          key={slide.title+'-'+index}
                        >
                        <div className="slider__slide-content" key={'slider-content'+index}>
                            {slide.alt !== null ?
                            <h3 className="slider__slide-subheading">
                              {slide.alt}
                            </h3>
                            : null
                            }
                            <h2 className="slider__slide-heading" key={'slider__slide-heading'+index}>
                              {slide.title.split('').map((l, index) => 
                              <span key={'span-'+slide.title+'-'+index}>{l}</span>
                              )}
                            </h2>
                            <p className="slider__slide-touch">TOUCH TO BEGIN</p>
                        </div>
                            <div className="slider__slide-parts" key={'slider__slide-parts'+index}>
                                {[...Array(IMAGE_PARTS).fill()].map((x, i) => (
                                <div className="slider__slide-part" key={i}>
                                    <div className="slider__slide-part-inner" key={'slider__slide-part-inner'+i} style={{ backgroundImage: `url(${slide.img})` }} />
                                </div>
                                ))}
                            </div>
                          </div>
                    ))}
                    </div>
            </div>
          </div>
          : 
            console.log('no screensaver data found!') 
          }
      </>
    )
}

export default Screensaver;