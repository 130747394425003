import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const LoadViewsData = () => async(dispatch) => {
  const viewsData = await apiService.getViewsData();
  
  const images = viewsData.map(o => {

    let w = Math.floor(1920 / 2);
    let h = Math.floor(1080 / 2);

    // const imgs = [
    return [
      {
        key: 'north-'+o.id, 
        title: 'North',
        src: `${assetBuilder(o.north)}`,
        img: `${assetBuilder(o.north)}`,
        thumbnail: `${assetBuilder(o.north)}?fit=cover&width=${w}&height=${h}&quality=80`,
        width: 1920/2,
        height: 1080/2,
        id: o.id,
        alt: 'north',
      }, {
        key: 'east-'+o.id, 
        title: 'East',
        src: `${assetBuilder(o.east)}`,
        img: `${assetBuilder(o.east)}`,
        thumbnail: `${assetBuilder(o.east)}?fit=cover&width=${w}&height=${h}&quality=80`,
        width: 1920/2,
        height: 1080/2,
        id: o.id,
        alt: 'east',
      }, {
        key: 'west-'+o.id, 
        title: 'West',
        src: `${assetBuilder(o.west)}`,
        img: `${assetBuilder(o.west)}`,
        thumbnail: `${assetBuilder(o.west)}?fit=cover&width=${w}&height=${h}&quality=80`,
        width: 1920/2,
        height: 1080/2,
        id: o.id,
        alt: 'west',
      }, {
        key: 'south-'+o.id, 
        title: 'South',
        src: `${assetBuilder(o.south)}`,
        img: `${assetBuilder(o.south)}`,
        thumbnail: `${assetBuilder(o.south)}?fit=cover&width=${w}&height=${h}&quality=80`,
        width: 1920/2,
        height: 1080/2,
        id: o.id,
        alt: 'south',
      }
    ];
  });

  dispatch({
    type: ACTION_TYPES.VIEWS_ACTIONS.FETCH_DATA,
    payload: {
      images,
      activeImage: 0,
      expandedView: false,
      // isLoaded: true
    }
  });
}

export const BochureSetActiveImage = (id) => {
  return {
    type: ACTION_TYPES.VIEWS_ACTIONS.SET_ACTIVE_IMAGE,
    payload: id
  }
}

export const BochureToggleExpandedView = (toggle) => {
  return {
    type: ACTION_TYPES.VIEWS_ACTIONS.TOGGLE_EXPANDED_VIEW,
    payload: (toggle === true || toggle === false) ? toggle : null
  }
}
