import React, {useEffect} from 'react';
import { useNavigate, useLocation  } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ImageIcon from '@mui/icons-material/Image';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PlaceIcon from '@mui/icons-material/Place';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PreviewIcon from '@mui/icons-material/Preview';
import apiService from '../services/apiService';

const drawerWidth = 280;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)})`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)})`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const activeLinkColour = '#1976d2';
  const linkColour = '#676767';
    
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  const container = document.getElementsByClassName('Container');
  
  export default function Navigation(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [openKeyplans, setOpenKeyplans] = React.useState(false);
    const [openViews, setOpenViews] = React.useState(false);
    const [openStory, setOpenStory] = React.useState(false);
    const [openSamplePlans, setOpenSamplePlans] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState('');
    const [viewsTitles, setViewsTitles] = React.useState([]);

    let navigate = useNavigate();

    const handleToggle = () => {
      if (!open){
        container[0].style.marginLeft = `${drawerWidth}px`;
        
      }else{
        container[0].style.marginLeft = '64px';
      }
        setOpen(!open);

    }

    const setPagetitle = ({title}) => {
      setCurrentPage({title});
    }

    const handleKeyplansToggle = () => {
        setOpenKeyplans(!openKeyplans);
        setCurrentPage('/keyplans');
        // location.pathname = '/keyplans';
    }

    const handleStoryToggle = () => {
      setOpenStory(!openStory);
      // setCurrentPage('/story');
      // location.pathname = '/keyplans';
  }

    const handleSamplePlansToggle = () => {
      setOpenSamplePlans(!openSamplePlans);
      setCurrentPage('/sampleplans');
      // location.pathname = '/sampleplans';
    }

    const handleViewsToggle = () => {
      setOpenViews(!openViews);
      setCurrentPage('/views');
      // location.pathname = '/views';
    }

    const handleNavLink = (url) => {
        setCurrentPage(url);
        navigate(url);
    }

    const location = useLocation();

    //fixes selected floorplan link colours
    if (location.pathname.startsWith('/keyplans/')){
      let url = location.pathname.replaceAll('%20', ' ');
      location.pathname = url;
    }

    if (location.pathname.startsWith('/sampleplans/')){
      let url = location.pathname.replaceAll('%20', ' ');
      location.pathname = url;
    }

    // if (location.pathname.startsWith('/story/')){
    //   let url = location.pathname.replaceAll('%20', ' ');
    //   location.pathname = url;
    // }

    // if (location.pathname.startsWith('/views/')){
    //   let url = location.pathname.replaceAll('%20', ' ');
    //   location.pathname = url;
    //   setViewsTitles(props.views);
    //   console.log('setviews: '+viewsTitles);
    // }

    // useEffect(() => {
    //   apiService.getViewsData().then((data) => {
    //     {console.log('data')}
    //     {console.log(data)}
    //     setViewsTitles(data);
    //   });
    // }, []);
    //   return () => {
    //     cleanup
    //   };
    // }, [input]);
  
    return (
      <Box 
        className="Navigation"
        sx={{ 
          display: 'flex' ,
        }}
      >
        <CssBaseline />
        
        <Drawer 
          className="nav-drawer" 
          variant="permanent" 
          open={open}
          PaperProps={{
            style: {
              zIndex: 200,
            },
          }}
        >
            {open ? (
                <div className="logoNav" style={{ marginTop: 64 }} onClick={(e)=>navigate('/')}>
                  <div className="logoImage" style={{height: 128, width: 128 }}/>
                </div>
            ) : (
                <div className="logoNav" style={{ marginTop: 64 }} onClick={(e)=>navigate('/')}>
                  <div className="logoImage" style={{height: 64, width: 64 }}/>
                </div>
            )}
          <DrawerHeader>
            <IconButton onClick={handleToggle} sx={{ width: 48, height: 48}}>
              {open ? <ChevronLeftIcon/> : <MenuIcon />}
            </IconButton>
          </DrawerHeader>

          <List className="nav-list" sx={{p:1}} >

            {/* AMENITIES */}
            <ListItem dense={false} sx={{p:1}}>
              <ListItemButton key={'amenities-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e) => handleNavLink('amenities')}>
                {location.pathname === '/amenities' ? 
                  <ListItemIcon style={{
                    color: `${activeLinkColour}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-center',
                    padding: theme.spacing(0, 1),
                  }}>
                    <PlaceIcon/>
                  </ListItemIcon>
                  :
                  <ListItemIcon style={{
                    color: `${linkColour}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-center',
                    padding: theme.spacing(0, 1),
                  }}>
                    <PlaceIcon/>
                  </ListItemIcon>
                  }
                  {location.pathname === '/amenities' ? 
                  <ListItemText variant="button" sx={{color: `${activeLinkColour}`}}>
                    AREA AMENITIES
                  </ListItemText>
                  :
                  <ListItemText variant="button" sx={{color: `${linkColour}`}}>
                    AREA AMENITIES
                  </ListItemText>
                  }
              </ListItemButton>
            </ListItem>

            {/* SAMPLE PLANS */}
            {props.sampleplans && props.sampleplans.length > 0 ? (
            <>
            <ListItem dense={false} sx={{p:1}}>
            <ListItemButton 
              key={'sampleplans-button'} 
              sx={{width: '240px', height: '48px', p: 0}} 
              onClick={handleSamplePlansToggle}
            >
                {location.pathname === '/sampleplans' ? 
                <ListItemIcon style={{
                  color: `${activeLinkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <PreviewIcon />
                </ListItemIcon>
                :
                <ListItemIcon style={{
                  color: `${linkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <PreviewIcon />
                </ListItemIcon>
              }
              {location.pathname === '/sampleplans' ? 
                <ListItemText primary="SAMPLE PLANS" 
                  sx={{
                    color: `${activeLinkColour}`,
                    textDecoration: 'none'
                  }}
                />
                :
                <ListItemText primary="SAMPLE PLANS"
                  sx={{
                    color: `${linkColour}`,
                    textDecoration: 'none'
                  }}
                />
              }
                {openSamplePlans ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            </ListItem>
            
              <Collapse in={openSamplePlans} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.sampleplans.map((item, index) => (
                      <ListItemButton key={item+'-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink(`/sampleplans/${item}`)}>
                        <ListItem dense={false} sx={{p:1}} key={index}>
                          {location.pathname === `/sampleplans/${item}` ? 
                              <ListItemText inset>
                                <Typography variant="body2" display="block" gutterBottom sx={{color: `${activeLinkColour}`}}>
                                  {item}
                                </Typography>
                              </ListItemText>
                          :
                            <ListItemText inset>
                              <Typography variant="body2" display="block" gutterBottom sx={{color: `${linkColour}`}}>
                                {item}
                              </Typography>
                            </ListItemText>
                          }
                        </ListItem>
                      </ListItemButton>
                    ))}

                    </List>
                </Collapse>
                </>
            ) : null }

            {/* KEYPLANS */}
            {console.log('props.keyplans')}
            {console.log(props.keyplans)}
            {props.keyplans && props.keyplans.length > 0 ? (
            <>
            <ListItem dense={false} sx={{p:1}}>
            <ListItemButton 
              key={'keyplans-button'} 
              sx={{width: '240px', height: '48px', p: 0}} 
              onClick={handleKeyplansToggle}
            >
                {location.pathname === '/keyplans' ? 
                <ListItemIcon style={{
                  color: `${activeLinkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <DashboardIcon />
                </ListItemIcon>
                :
                <ListItemIcon style={{
                  color: `${linkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <DashboardIcon />
                </ListItemIcon>
              }
              {location.pathname === '/keyplans' ? 
                <ListItemText primary="KEYPLANS" 
                  sx={{
                    color: `${activeLinkColour}`,
                    textDecoration: 'none'
                  }}
                />
                :
                <ListItemText primary="KEYPLANS"
                  sx={{
                    color: `${linkColour}`,
                    textDecoration: 'none'
                  }}
                />
              }
                {openKeyplans ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            </ListItem>
            
              <Collapse in={openKeyplans} timeout="auto" unmountOnExit>

              <List component="div" disablePadding>
                  {props.keyplans.map((item, index) => (
                    <ListItemButton key={item+'-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink(`/keyplans/${item}`)}>
                      <ListItem dense={false} sx={{p:1}} key={index}>
                        {location.pathname === `/keyplans/${item}` ? 
                            <ListItemText inset>
                              <Typography variant="body2" display="block" gutterBottom sx={{color: `${activeLinkColour}`}}>
                                {item}
                              </Typography>
                            </ListItemText>
                        :
                          <ListItemText inset>
                            <Typography variant="body2" display="block" gutterBottom sx={{color: `${linkColour}`}}>
                              {item}
                            </Typography>
                          </ListItemText>
                        }

                      </ListItem>
                    </ListItemButton>
                  ))}

                  </List>

              </Collapse>
                </>
            ) : null }

          {/* RENDERINGS */}
          <ListItem dense={false} sx={{p:1}}>
            <ListItemButton key={'renderings-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink('/renderings')}>
            {location.pathname === '/renderings' ? 
              <ListItemIcon style={{
                color: `${activeLinkColour}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <ImageIcon/>
              </ListItemIcon>
              :
              <ListItemIcon style={{
                color: `${linkColour}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <ImageIcon/>
              </ListItemIcon>
            }

              {location.pathname === '/renderings' ? 
                <ListItemText sx={{color: `${activeLinkColour}`}}>RENDERINGS</ListItemText>
              :
                <ListItemText sx={{color: `${linkColour}`}}>RENDERINGS</ListItemText>
              }
                </ListItemButton>
            </ListItem>

            {/* VIEWS */}
            {/* {console.log('views! ' + props.views.length)} */}
            {console.log('views!')}
            {console.log(props)}
            {props.views && props.views.length > 0 ? (
            <>
            <ListItem dense={false} sx={{p:1}}>
            <ListItemButton 
              key={'views-button'} 
              sx={{width: '240px', height: '48px', p: 0}} 
              onClick={handleViewsToggle}
            >
                {location.pathname === '/views' ? 
                <ListItemIcon style={{
                  color: `${activeLinkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <VisibilityIcon />
                </ListItemIcon>
                :
                <ListItemIcon style={{
                  color: `${linkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <VisibilityIcon />
                </ListItemIcon>
              }
              {location.pathname === '/views' ? 
                <ListItemText primary="VIEWS" 
                  sx={{
                    color: `${activeLinkColour}`,
                    textDecoration: 'none'
                  }}
                />
                :
                <ListItemText primary="VIEWS"
                  sx={{
                    color: `${linkColour}`,
                    textDecoration: 'none'
                  }}
                />
              }
                {openViews ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            </ListItem>
            
              <Collapse in={openViews} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/* {props.views.map((item, index) => ( */}
                    {console.log('views:')}
                    {console.log(props.views)}
                    {props.views.map((item, index) => (
                      // <ListItemButton key={item+'-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink(`/views/${item}`)}>
                      <ListItemButton key={item+'-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink(`/views/${item.split('-')[1]}`)}>
                        <ListItem dense={false} sx={{p:1}} key={index}>
                          {location.pathname === `/views/${item.split('-')[1]}` ? 
                              <ListItemText inset>
                                <Typography variant="body2" display="block" gutterBottom sx={{color: `${activeLinkColour}`}}>
                                  {/* {item} */}
                                  {item.split('-')[0]}
                                </Typography>
                              </ListItemText>
                          :
                            <ListItemText inset>
                              <Typography variant="body2" display="block" gutterBottom sx={{color: `${linkColour}`}}>
                                {/* {item} */}
                                {item.split('-')[0]}
                              </Typography>
                            </ListItemText>
                          }
                        </ListItem>
                      </ListItemButton>
                    ))}

                    </List>
                </Collapse>
                </>
            ) : null }


            {/* STORY */}  
            {/* <ListItem dense={false} sx={{p:1}}>
              <ListItemButton key={'story-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink('/story')}>
              {location.pathname === '/story' ? 
                <ListItemIcon style={{
                  color: `${activeLinkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }} >
                  <MenuBookIcon/>
                </ListItemIcon>
                :
                <ListItemIcon style={{
                  color: `${linkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }} >
                  <MenuBookIcon/>
                </ListItemIcon>
              }

                {location.pathname === '/story' ? 
                  <ListItemText sx={{color: `${activeLinkColour}`}}>BUILDER STORY</ListItemText>
                :
                  <ListItemText sx={{color: `${linkColour}`}}>BUILDER STORY</ListItemText>
                }
              </ListItemButton>
            </ListItem> */}

            {/* STORY */} 
            {props.stories && props.stories.length > 0 ? (
            <>
            <ListItem dense={false} sx={{p:1}}>
            <ListItemButton 
              key={'story-button'} 
              sx={{width: '240px', height: '48px', p: 0}} 
              onClick={handleStoryToggle}
            >
                {location.pathname === '/story' ? 
                <ListItemIcon style={{
                  color: `${activeLinkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <MenuBookIcon />
                </ListItemIcon>
                :
                <ListItemIcon style={{
                  color: `${linkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <MenuBookIcon />
                </ListItemIcon>
              }
              {location.pathname === '/story' ? 
                <ListItemText primary="BUILDER STORY" 
                  sx={{
                    color: `${activeLinkColour}`,
                    textDecoration: 'none'
                  }}
                />
                :
                <ListItemText primary="BUILDER STORY"
                  sx={{
                    color: `${linkColour}`,
                    textDecoration: 'none'
                  }}
                />
              }
                {openStory ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            </ListItem>
            
              <Collapse in={openStory} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/* {props.views.map((item, index) => ( */}
                    {console.log('story:')}
                    {console.log(props.stories)}
                    
                    {props.stories.map((item, index) => (
                      // <ListItemButton key={item+'-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink(`/views/${item}`)}>
                      <ListItemButton key={item+'-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink(`/story/${item.split('-')[1]}`)}>
                        <ListItem dense={false} sx={{p:1}} key={index}>
                          {location.pathname === `/story/${item.split('-')[1]}` ? 
                              <ListItemText inset>
                                <Typography variant="body2" display="block" gutterBottom sx={{color: `${activeLinkColour}`}}>
                                  {/* {item} */}
                                  {item.split('-')[0]}
                                </Typography>
                              </ListItemText>
                          :
                            <ListItemText inset>
                              <Typography variant="body2" display="block" gutterBottom sx={{color: `${linkColour}`}}>
                                {/* {item} */}
                                {item.split('-')[0]}
                              </Typography>
                            </ListItemText>
                          }
                        </ListItem>
                      </ListItemButton>
                    ))}

                    </List>
                </Collapse>
                </>
            ) : null }

            {/* BROCHURE */}
            <ListItem dense={false} sx={{p:1}}>
            <ListItemButton key={'brochure-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink('brochure')}>
            {location.pathname === '/brochure' ? 
              <ListItemIcon style={{
                color: `${activeLinkColour}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <PictureAsPdfIcon/>
              </ListItemIcon>
              :
              <ListItemIcon style={{
                color: `${linkColour}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <PictureAsPdfIcon/>
              </ListItemIcon>
            }
              {location.pathname === '/brochure' ? 
                <ListItemText sx={{color: `${activeLinkColour}`}}>BROCHURE</ListItemText>
              :
                <ListItemText sx={{color: `${linkColour}`}}>BROCHURE</ListItemText>
              }
                </ListItemButton>
            </ListItem>
            
            {/* investorbrochure */}
            <ListItem dense={false} sx={{p:1}}>
              <ListItemButton key={'investor-brochure-button'} sx={{width: '240px', height: '48px', p: 0}} onClick={(e)=>handleNavLink('investorbrochure')}>
              {location.pathname === '/investorbrochure' ? 
                <ListItemIcon style={{
                  color: `${activeLinkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                  <RequestQuoteIcon/>
                </ListItemIcon>
                :
                <ListItemIcon style={{
                  color: `${linkColour}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                  <RequestQuoteIcon/>
                </ListItemIcon>
              }
                {location.pathname === '/investorbrochure' ? 
                  <ListItemText sx={{color: `${activeLinkColour}`}}>INVESTOR BROCHURE</ListItemText>
                :
                  <ListItemText sx={{color: `${linkColour}`}}>INVESTOR BROCHURE</ListItemText>
                }
                </ListItemButton>
              </ListItem>

          </List>
        </Drawer>
        </Box>
    );
  }