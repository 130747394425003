import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, useParams } from "react-router-dom";

import Gallery from './Gallery/Gallery';

import { LoadKeyplansData } from '../redux/actions/keyplansActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";


const Keyplans = (props) => {

    let params = useParams();
    
    document.title = 'Keyplans';
    document.documentElement.style.setProperty('--asset-background_image', 'none');

    useEffect(() => {
        // console.log('fetching with filter: '+params.id);
        // setFilter(params.id);
        props.LoadKeyplansData(params.id);
        // setCurrentImageIndex(0);
    }, [params.id]);

    return (
      <React.Fragment>
        {props.keyplans && props.keyplans.images.length > 0 ?
            <Gallery images={props.keyplans.images} type={"renderings"}/>
        :  null
        }
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return { keyplans: state.keyplans }
}

export default withRouter(connect(mapStateToProps, { LoadKeyplansData })(Keyplans));