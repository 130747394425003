import React from 'react';
import floorplansService from '../../../../services/floorplansService';
import { connect } from 'react-redux';
import { BookmarkRemove } from '../../../../redux/actions/bookmarksActions';
import A from '../../../generic/A';
import assetBuilder from '../../../../services/assetBuilder';

class FavouritesFloorplanItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {remove: false};
    this.animTime = 250;
  }

  removeItem = () => {
    const { item } = this.props;
    setTimeout(() => {
      this.props.BookmarkRemove('floorplans', [item]);
    }, this.animTime)
    this.setState({remove:true})
  }

  render() {
    const { item, floorplan } = this.props;

    const units = floorplan.units.filter(f => f.suite === item.id);
    const exposures = floorplansService.getExposureList(units, true);

    return (
      <div className={`fav-list-content-item fav-list-content-item-fp ${this.state.remove ? 'fav-list-content-item-hide' : ''}`}>
        <div className="fav-list-content-item-container fav-list-content-item-container-fp">
          <img height="80px" width="80px" src={assetBuilder(item.floorplan_thumb)} alt="thumbnail" style={{objectFit: "contain"}}/>
          <div className="fv-lst-cnt-txt fv-lst-cnt-txt-bld"><div>{item.title}</div></div>
          <div className="fv-lst-cnt-txt fv-lst-cnt-mb-hd"><div>{item.description}</div></div>
          <div className="fv-lst-cnt-txt fv-lst-cnt-mb-hd"><div>{`${item.size} sq.ft.`}</div></div>
          <div className="fv-lst-cnt-txt fv-lst-cnt-mb-hd"><div>{exposures}</div></div>
          <A className="fv-lst-cnt-txt" onClick={this.removeItem}><span className="icon-cancel"/></A>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => { return { floorplan: state.floorplan } }
export default connect(mapStateToProps, { BookmarkRemove })(FavouritesFloorplanItem);