import React, {useEffect} from 'react';

const MainPage = (props) => {

  useEffect(() => {
    document.title = '75 James';
    document.documentElement.style.setProperty('--asset-background_image', `url(${props.project.background_image})`);
  }, []);

  return (
      <div className="main">
      </div>
  )
}

export default MainPage;
  