import React, {useState, useEffect, useCallback, useRef} from 'react';
import A from "../../../components/generic/A";
import Input from '../../../components/generic/Input';
import apiService from '../../../services/apiService';

import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import Badge from '@mui/material/Badge';
// import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
// import FavouritesButton from './FavouritesButton';
import FavouritesList from './list/FavouritesList';
import { BookmarkFetchFromCache, BookmarkRemove } from '../../../redux/actions/bookmarksActions';
import FavouritesEmail from './email/FavouritesEmail';
import { connect } from "react-redux";
import Badge from '@mui/material/Badge';
import FavouritesButton from './FavouritesButton';
import FavouriteBadge from './FavouriteBadge';
import FavouriteItem from './FavouriteItem';
import FavouriteGroup from './FavouriteGroup';
import { ClickAwayListener } from '@mui/base';

import Bookmark from '../../generic/Bookmark';

const Favorites = (props) => {
  const [open, setOpen] = React.useState(false);
  const [favourites, setFavourites] = React.useState([]);
  const [favs, setFavs] = React.useState([]);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [renderingsOpened, setRenderingsOpened] = React.useState(true);
  const [floorplansOpened, setFloorplansOpened] = React.useState(true);
  const [email, setEmail] = React.useState('');
  const [emails, setEmails] = React.useState([]);
  const [progressText, setProgressText] = React.useState('');
  const [sendInProgress, setSendInProgress] = React.useState(false);
  const [disclaimer, setDisclaimer] = React.useState(false);

  // console.log(props.bookmarks);

  // const handleRemove = (id) => {

  //   // let clonedFavs = {...favs};
  //   // delete clonedFavs[id];
  //   // setFavs( shopCart => ({
  //   //   ...copiedShopCart
  //   // }));

  //   // console.log('favs');
  //   // console.log(favs);
  //   // let newData = 
  //   // setFavs(favs.floorplans.filter((item) => item.id !== id));
  //   // setFavs(favs.renderings.filter((item) => item.id !== id));
  //   // console.log('remove: '+id);
  //   // setFavs(newList);
  // }

  

  const clearFavourites = () => {
    console.log('clearing favourites!');
    
    for (var i=0; i < props.bookmarks.floorplans.length; i++) {

      
      // let clonedFavs = {...favs};
      // let filteredData = props.bookmarks.floorplans.filter((item) => item.id !== props.bookmarks.floorplans[i].id);
      // console.log(filteredData);
      // delete clonedFavs[id];
      // setFavs( shopCart => ({
      //   ...copiedShopCart
      // }));
      // setFavs(filteredData);
      // handleRemove(props.bookmarks.floorplans[i].id);
      // if (props.bookmarks.floorplans[i] === props.bookmarks.floorplans[0])
      // {
      //   console.log('match!');
      //   console.log(props.bookmarks.floorplans[i]);
      //   console.log(props.bookmarks.floorplans[0]);
      // }
      
      BookmarkRemove('floorplans', props.bookmarks.floorplans[i]);
      // console.log(`removing floorplan: ${props.bookmarks.floorplans[i].title}`);
    }

    
    for (var i=0; i < props.bookmarks.renderings.length; i++) {
    // for (let bookmark of props.bookmarks.assets) {
      // let filteredData = props.bookmarks.renderings.filter((item) => item.id !== props.bookmarks.renderings[i].id);
      // console.log(filteredData);
      // setFavs(filteredData);
      BookmarkRemove('renderings', props.bookmarks.renderings[i]);
      // console.log(`removing renderings: ${props.bookmarks.renderings[i].title}`);
      // handleRemove(props.bookmarks.renderings[i].id);
      
    }
    // BookmarkClear();
    // props.bookmarks.floorplans = [];
    // props.bookmarks.renderings = [];
    // console.log(props.bookmarks);

    setFavs([]);
  }

  const handleDrawerOpen = useCallback(event => {
    setOpen(true);
  });

  const handleDrawerClose = useCallback(event => {
    // if (event.reason === "backdropClick"){
    //   console.log('clicked!!');
      
    //   // return false;
    //   setOpen(true);
    // }
    // console.log(event);
    setOpen(false);
  });

  const toggleDrawer = useCallback(event => {
    setOpen(!open);
  });

  // useEffect(() => {
  //   let favCount = props.bookmarks.floorplans.length + props.bookmarks.renderings.length;
  //   setFavourites(favCount);
  // }, []);

  // useEffect(()=> {
  //   setFavs(props.bookmarks);
  //   console.log('favs');
  //   console.log(favs);
  // }, [props.bookmarks]);

  const toggleAcceptance = useCallback(event => {
    setAcceptedTerms(!acceptedTerms);
  });

  const onEmailChange = useCallback(event => {

    <Chip label="Chip Filled" />
  })

  const toggleFloorplans = useCallback(event => {
    setFloorplansOpened(!floorplansOpened);
  });

  const toggleRenderings = useCallback(event => {
    setRenderingsOpened(!renderingsOpened);
  });

  const validateEmail = useCallback(event => {
    const re = /\S+@\S+\.\S+/;
    return re.test(event);
  });

  const sendEmail = useCallback(event => {
    const email = emails.join(',');
    const data = props.bookmarks;
    setProgressText('Sending...');
    setSendInProgress(true)
    apiService.postFavouritesList(data, email, onSendComplete);
  })

  const onSendComplete = (status) => {
    const text = status === 200 ?
      'Your favourites have been sent!' :
      'Error occured when sending favourites...'
    
    if (status === 200){
      clearFavourites();
    }

    setProgressText(text);
    setSendInProgress(false)
  }

  const handleChange = (event, force) => {
    // setEmail(event.target.value);
    // console.log(event.target.value);
    const v = event.target.value;

    const lastEntry = v[v.length - 1];
    let currentEmails = [...emails];

    if (!force && lastEntry !== ',' && lastEntry !== ' ') {
      setEmail(v);
      return;
    }

    let newString = '';
    const entries = v.split(',');
    for (let entry of entries) {
      let email = entry.trim()
      let isValid = validateEmail(email);
      // console.log('entry is ', entry)
      if (email !== '' && isValid) {
        // console.log(`${entry} is a valid email`)
        if (!currentEmails.includes(email)) 
          currentEmails = [...currentEmails, email];
        newString = ''
      } else {
        newString = entry;
      }
    }
    setEmail(newString);
    setEmails(currentEmails);
    // console.log('currentEmails');
    // console.log(emails);
    event.target.value = '';
  };

  const onInputChange = (v, force) => {
    // Take the string and divide it by ,s
    // Trim each line and verify if it validates the email
    // Check if the current email list contains it, if not add it
    // Set the input to the last out of the list
    // console.log(v);
    if (force)
    v = email;
    
    const lastEntry = v[v.length - 1];
    let currentEmails = [...emails];

    if (!force && lastEntry !== ',' && lastEntry !== ' ') {
      setEmail(v);
      return;
    }

    // console.log('entries');
    // console.log(v);
    let newString = '';
    const entries = v.split(',');
    for (let entry of entries) {
      let email = entry.trim()
      let isValid = this.validateEmail(email);
      console.log('entry is ', entry)
      if (email !== '' && isValid) {
        console.log(`${entry} is a valid email`)
        if (!currentEmails.includes(email)) 
          currentEmails = [...currentEmails, email];
        newString = ''
      } else {
        newString = entry;
      }
    }
    setEmail(newString);
    setEmails(currentEmails);
  }

  const handleDelete = useCallback(e => {
    // {console.log('removed email: '+e)}
    setEmails([...emails].filter(item => item !== e))
  })

  const renderChips = () => {
    return (
      <div className="fav-email-chips-container">
        {/* {console.log(emails)} */}
        {emails.map((e, i) => <Chip key={i} label={e} onDelete={()=> handleDelete(e)} />)}
      </div>
    )
  }

  // const blurred = () => {
  //   console.log('blurred!');
  // }

  const renderEmailInput = () => {
    return (
      // <div className="fav-email-input">
      <>
        {renderChips()}
        <TextField 
        style={{userSelect: true}}
          ref={emailRef}
          sx={{my:2, width: '100%'}}
          id="email-input"
          label="Email"
          helperText="Enter multiple emails separated by a comma"
          variant="standard"
          // value={email}
          // onChange={(v) => onInputChange(v, true)}
          onBlur={(e)=>handleChange(e, true)}
          onChange={(e)=>handleChange(e, false)}
        />
        {/* <Input
          placeholder="Enter multiple emails separated by a comma"
          value={email}
          onChange={(v) => onInputChange(v, false)}
          onFocusOut={(v) => onInputChange(v, true)}
          height="44px"
        /> */}
        </>
      // </div>
    );
  }

  // const renderEmailDisclaimer = () => {
  //   const active = disclaimer;
  //   return (
  //     <div className="fav-email-disc" ref={props.innerRef}>
  //       <input id="fav-email-disc" type='checkbox' checked={active} onChange={() => this.setState({ disclaimer: !active })} />
  //       <label className="fav-email-disc-text" htmlFor="fav-email-disc">
  //         {this.props.disclaimer}
  //       </label>
  //     </div>
  //   )
  // }

  const renderSendButton = () => {
    const active = acceptedTerms && emails.length > 0 && !sendInProgress;
    // console.log(`active: ${acceptedTerms} emails.length: ${emails.length > 0} send progress: ${!sendInProgress}`);
    return (
      <>
      <Button variant="contained" href="#contained-buttons" sx={{my:2}} onClick={sendEmail} disabled={!active}>
        Send
      </Button>

      <Typography>
          {progressText}
      </Typography>
      </>
      // <Button 
      //   variant="contained" 
      //   href="#contained-buttons" 
      //   sx={{my:2}} 
      //   onClick={sendEmail}
      //   disabled={!active}
      //   text="Send"
      // />
      // <Button
      //   className="fav-email-btn"
      //   text="Send"
      //   width="100px"
      //   disabled={!active}
      //   onClick={this.sendEmail}
      // />
    );
  }

  const trimKeys = (props) => {
    var n = props.indexOf('?');
    return props.substring(0, n != -1 ? n : props.length);
  }

  const emailRef = useRef();
    
  // const handleBackdropClick = (event, reason) => {
  //   if (reason === "backdropClick"){
  //     return false;
  //   }
  //   //these fail to keep the modal open
  //   // event.stopPropagation();
  //   // return false;
  // };


  return (
      <React.Fragment>
        <A onClick={toggleDrawer}> 
              <FavouritesButton count={favourites}/>
        </A>
        {/* <ClickAwayListener onClickAway={handleDrawerClose}> */}
        <SwipeableDrawer
          variant="temporary"
          className={'FavMenu'}
          anchor={'right'}
          open={open}
          // onEscapeKeyDown={handleDrawerClose} 
          // onBackdropClick={handleDrawerClose}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          PaperProps={{sx: { width: '25%' },}}
          // sx={{width: 30}}
          // onClose={toggleDrawer}
          ModalProps={{
            // onBackdropClick: {handleBackdropClick},
            keepMounted: true, // Better open performance on mobile.

          }}
        >
          <Box 
            className="favListItems noselect"
            sx={{p:4}} 
            style={{
              height: '70%',
              overflow: 'scroll', 
              overflowX: 'hidden', 
              overflowY: 'scroll',
              scrollbarWidth: 'none',
            }}
            role="presentation"
            // onClick={toggleDrawer}
            // onKeyDown={toggleDrawer}
          >
          <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 16, paddingBottom: 16}}>
            <div style={{display: 'flex'}}>
              <Typography variant="h5">
                My Favourites
              </Typography>
              <FavouriteBadge count={props.bookmarks.floorplans.length + props.bookmarks.renderings.length}/>
            </div>
            {/* <BookmarkIcon sx={{mx:2, justifyContent: 'center', color: '#0069FF'}}/> */}
            <CloseIcon sx={{right: '0px', height: '32px'}} onClick={handleDrawerClose} /> 
          </div>
         
          {/* <div className="fav-content hidden-scroll" style={{paddingBottom: this.state.padding - 50}}>
            <FavouritesList content={floorplans} name="Floorplans" type='floorplans' />
            <FavouritesList content={renderings} name={"Renderings"} type='renderings' />
            <FavouritesList content={media} name="Marketing Material" type='media' />
            <FavouritesEmail ref={this.emailRef}/>
          </div> */}
            
            
            {/* // {favs.renderings && favs.renderings.length > 0 ? */}
              <List className="FavList" >
                {props.bookmarks.renderings && props.bookmarks.renderings.length > 0 ?
                <div className="favourite-renderings" style={{padding: '12px', backgroundColor: "#f5f5f5"}}>
                  <div style={{display: 'flex'}}>
                    <Typography variant="h6">
                        Renderings
                    </Typography>
                    <Badge badgeContent={props.bookmarks.renderings.length} overlap="circular" color="primary" sx={{right: -24, top: 8}}/>
                    {/* <Badge badgeContent={favs.renderings.length} overlap="circular" color="primary" sx={{right: -24, top: 8}}/> */}
                    {renderingsOpened ?
                      <ExpandLessIcon style={{right: 0, position: 'absolute', height: '32px', width: '28px'}} sx={{mx:4}} onClick={toggleRenderings}/>
                    :
                      <ExpandMoreIcon style={{right: 0, position: 'absolute', height: '32px', width: '28px'}} sx={{mx:4}} onClick={toggleRenderings}/>
                    }
                  </div> 
                  {renderingsOpened ?
                  <>
                  <Divider sx={{my: 1}}/> 
                  {props.bookmarks && props.bookmarks.renderings.map(item => (
                  //{/* {favs.renderings.map(item => ( */}
                    <ListItem key={item.id}>
                          <>
                            {item.isPDF ?
                            <>
                              {/* {console.log(trimKeys(`${item.src}`))} */}
                              {/* var n = s.indexOf('?');
                              s = s.substring(0, n != -1 ? n : s.length); */}
                              <img src={trimKeys(`${item.src}`)+`?fit=contain&width=80&height=46&quality=80`} alt={item.title}/>
                              {/* <img src={`${item.thumbnail}?fit=contain&width=80&height=46&quality=80`} alt={item.title}/> */}
                            </>
                            : 
                              <img src={`${item.img}?fit=contain&width=80&height=46&quality=80`} alt={item.title}/>
                            }
                            <ListItemText primary={item.title} sx={{p:2}} inset={true}/>
                            <Bookmark type="renderings" item={item} delete={true} />
                          </>
                    </ListItem>
                  ))}
                  </>
                  :
                  null }
                </div>
                 : 
                 null } 
              </List>
           

            {props.bookmarks.floorplans && props.bookmarks.floorplans.length > 0 ?
            //{/* {favs.floorplans && favs.floorplans.length > 0 ? */}
              <List className="FavList" >
                <div className="favourite-floorplans" style={{padding: '12px', backgroundColor: "#f5f5f5"}}>
                  <div style={{display: 'flex'}} >
                    <Typography variant="h6">
                        Floorplans
                    </Typography>
                    <Badge badgeContent={props.bookmarks.floorplans.length} overlap="circular" color="primary" sx={{right: -24, top: 8}}/>
                    {/* <Badge badgeContent={favs.floorplans.length} overlap="circular" color="primary" sx={{right: -24, top: 8}}/> */}
                    {floorplansOpened ?
                      <ExpandLessIcon style={{right: 0, position: 'absolute', height: '32px', width: '28px'}} sx={{mx:4}} onClick={toggleFloorplans}/>
                    :
                      <ExpandMoreIcon style={{right: 0, position: 'absolute', height: '32px', width: '28px'}} sx={{mx:4}} onClick={toggleFloorplans}/>
                    }
                  </div>  
                  {floorplansOpened ?
                  <>
                  <Divider sx={{my: 1}}/> 
                  {props.bookmarks.floorplans && props.bookmarks.floorplans.map(item => (
                  // {favs.floorplans.map(item => (
                    <ListItem key={item.id}>
                          <>
                            <img src={`${item.img}?fit=contain&width=80&height=46&quality=80`} alt={item.title}/>
                            <ListItemText primary={item.title} sx={{p:2}}/>
                            <Bookmark type="floorplans" item={item} delete={true} />
                          </>
                    </ListItem>
                  ))}
                  </>
                  : 
                  null }
                </div>
              </List>
            : 
            null }
          </Box>

          {/* email section */}
          <Box style={{ width: '100%', bottom: 0, position: 'absolute', borderTop: '1px solid #d2d2d7'}} boxShadow={20} sx={{px: 4, py: 2, backgroundColor: "#f5f5f5"}}>
            <Typography variant="h6">
              Email My Favourites
            </Typography>
            {/* <TextField 
              ref={emailRef}
              sx={{my:2, width: '100%'}}
              id="email-input"
              label="Email"
              helperText="Enter multiple emails separated by a comma"
              variant="standard"
            /> */}
            {/* <FavouritesEmail ref={emailRef}/> */}
            
            {renderEmailInput()}
            
            <Box sx={{ m:0}} style={{display: 'flex', cursor: 'pointer'}} onClick={toggleAcceptance}>
              <Checkbox style={{top: '0px', margin: '0px 0px auto', paddingTop: 0, userSelect: 'none'}} checked={acceptedTerms}/>
              <Typography variant="caption" display="block" sx={{color: '#777777', fontSize: 10}} paragraph={true}>
                By clicking this box I expressly provide my consent to receive electronic messages from (Trisev and its affiliates) retroactively, today and in the future. I understand that I may withdraw my consent by unsubscribing at any time.
              </Typography>
            </Box>

            {renderSendButton()}
            {/* <Button variant="contained" href="#contained-buttons" sx={{my:2}} onClick={sendEmail} disabled={!active}>
                Send
            </Button> */}
          </Box>

        </SwipeableDrawer>
        {/* </ClickAwayListener> */}
      </React.Fragment>
  );
}

const mapStateToProps = state => { return { bookmarks: state.bookmarks } };
export default connect(mapStateToProps, { BookmarkFetchFromCache, BookmarkRemove })(Favorites);
// export default connect(mapStateToProps, { BookmarkFetchFromCache, BookmarkRemove, BookmarkClear })(Favorites);