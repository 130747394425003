import ACTION_TYPES from '../actionTypes.json';

export const toggleMenu = (isActive) => {
  return {
    type: ACTION_TYPES.MENU.TOGGLE_MENU,
    payload: (isActive === true || isActive === false) ? isActive : null
  }
}

export const toggleFavorites = (isActive) => {
  return {
    type: ACTION_TYPES.MENU.TOGGLE_FAVORITES,
    payload: (isActive === true || isActive === false) ? isActive : null
  }
}

export const toggleHelp = (isActive) => {
  return {
    type: ACTION_TYPES.MENU.TOGGLE_HELP,
    payload: (isActive === true || isActive === false) ? isActive : null
  }
}