import React, {useEffect, Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from './components/Navigation';
import Amenities from './components/Amenities';
import MainPage from './components/MainPage';
import Story from './components/Story';
import Views from './components/Views';
import Brochure from './components/Brochure';
import InvestorBrochure from './components/InvestorBrochure';
import SamplePlans from './components/SamplePlans';
import Keyplans from './components/Keyplans';
import Renderings from './components/Renderings';
import Favourites from './components/modals/Favourites/Favourites';

import { useIdleTimer } from 'react-idle-timer'
import { toggleMenu } from "./redux/actions/menuActions";
import { toggleFavorites } from "./redux/actions/menuActions";
import { LoadProjectData } from './redux/actions/projectActions';

import { loadScreenSaverData } from './redux/actions/screensaverActions';
import { connect } from 'react-redux';

import './App.css';
import Screensaver from './components/Screensaver/Screensaver';
import { PropaneSharp } from '@mui/icons-material';

function App(props) {

  const [idle, setIdle] = React.useState(false);

  const onIdle = () => {
    setIdle(true);
  }

  const onActive = (event) => {
    setIdle(false);
  }

  const idleTimer = useIdleTimer({ 
    timeout: 1000 * 60 * 15,
    onIdle, 
    onActive 
  })

  var containerStyle = {
      padding: 24,
      marginLeft: 280,
      transition: 'margin 700ms',
      height: '100%'
  };

  useEffect(() => {
    props.LoadProjectData();
    props.loadScreenSaverData();
  }, []);

  useEffect(()=> {
    document.documentElement.style.setProperty('--asset-background_image', `url(${props.project.background_image})`);
  }, [props.project.background_image]);
  

  return (
      <div className="Background">
          <Router>
            <Suspense fallback={<div> loading... </div>}>
            {props.project != undefined ? (
              <React.Fragment>
              {idle ?
                <>
                  {props.screensaver.isLoaded ?
                    <Screensaver images={props.screensaver} active={idle}/>
                  : console.log('loading screensaver....')
                  }
                </>
              : null }

                <Navigation keyplans={props.project.keyplans} sampleplans={props.project.sampleplans} views={props.project.views} stories={props.project.story} />
                

                {/* <Favourites/> */}
              
                <div className="Container" style={containerStyle} tag="layout">
                  <Routes>
                    <Route path='/' element={<MainPage project={props.project} />} />
                    <Route path='/amenities' element={<Amenities />} />
                    <Route path='/sampleplans' element={<SamplePlans />} />
                    <Route path='/sampleplans/:id' element={<SamplePlans />} />
                    <Route path='/keyplans' element={<Keyplans />} />
                    <Route path='/keyplans/:id' element={<Keyplans />} />
                    <Route path='/renderings' element={<Renderings />} />
                    <Route path='/story' element={<Story />} />
                    <Route path='/story/:id' element={<Story />} />
                    <Route path='/views' element={<Views />} />
                    <Route path='/views/:id' element={<Views />} />
                    <Route path='/brochure' element={<Brochure />} />
                    <Route path='/investorbrochure' element={<InvestorBrochure />} />
                  </Routes>
                </div>
                </React.Fragment>
              ) : console.log('getting project data...')}
              
              </Suspense>
          </Router>
      </div>
  );
}


const mapStateToProps = (state) => { 
  return { 
    project: state.project,
    views: state.views,
    toggleFavorites: state.toggleFavorites,
    screensaver: state.screensaver,
  }
}

export default connect(mapStateToProps, { LoadProjectData, toggleMenu, toggleFavorites, loadScreenSaverData })(App);
