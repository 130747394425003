import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const LoadProjectData = () => async(dispatch) => {
  const projectData = await apiService.getProjectData();
  
  projectData.background_image = assetBuilder(projectData.background_image);

  console.log('projectData');
  console.log(projectData);
  dispatch({
    type: ACTION_TYPES.LOAD_PROJECT_DATA,
    payload: projectData,
    // isLoaded: true
  });
}
