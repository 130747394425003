import axios from 'axios';
import _ from 'lodash';
import store from '../redux/store';
import config from '../configs/config.json';
import { AuthenticateRefresh } from '../features/authentication/authenticationActions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

class apiService {
  static service = axios.create({
    baseURL: config.API_BaseUrl
  });

  static throwApiRequestFailedException = (response) => {
    throw new Error(`API Request failed (${response.data.errors ? response.data.errors[0].message : 'No Error Message'})`);
  }

  static _callService = async (url, method = "get", data = null, auth = true) => {
    // If auth is enabled, get auth header
    let headers = null;
    if (auth) {
      const authState = store.getState().authentication;
      if (!authState.isAuthenticated)
        throw new Error("User is not authenticated");
      headers = { 'Authorization': `Bearer ${authState.access_token}`,
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods' : 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization'
       };
    }

    // Call service
    try {
      const response = await this.service.request({ method, url, headers, data });
      return response;
    } catch (err) {
      const response = err.response;
      if (response.status !== 401 || auth === false)
        this.throwApiRequestFailedException(response);
        
      // Refresh the API token
      const authState = store.getState().authentication;
      const credentials = await this.service.request({
        url: '/auth/refresh',
        method: 'post',
        data: { 'refresh_token': authState.refresh_token }
      })

      // If failed, throw error
      if (credentials.status !== 200)
        this.throwApiRequestFailedException(response);

      // Update credentials 
      store.dispatch(AuthenticateRefresh(credentials.data.data));

      // Throw request with the new credentials
      headers = { 'Authorization': `Bearer ${credentials.data.data.access_token}` };
      try {
        const resp = await this.service.request({ method, url, headers, data });
        return resp;
      } catch (err) {
        this.throwApiRequestFailedException(response);
      }
    }
  };

  // API Calls
  // auth/login
  static authenticate = async () => {
    const data = {
      "email": "admin@trisev.com",
      "password": "WSET#$6gbsadg#$^"
    }
    const results = await this._callService('/auth/login', 'post', data, false);
    return results.data.data;
  };

  //Area Amenities
  static getAmenitiesData = _.memoize(async () => {
    const results = await this._callService('/areaamenities/', 'get', null, false);
    console.log('Fetching Area Amenities Data...');
    console.log(results.data);
    return results.data;
  });

  //Sample Plans
  static getSamplePlansData = _.memoize(async () => {
    const results = await this._callService('/sampleplans/', 'get', null, false);
    console.log('Fetching Sample Plans Data...');
    console.log(results.data);
    return results.data;
  });

    // Sample Plans Filter
  static getFilteredSamplePlansData = _.memoize(async (props) => {
    // console.log('getFloorplansData');
    // console.log(`/floorplans/?filter[category][_eq]=${props}`);
    const results = await this._callService(`/sampleplans/?filter[category][_eq]=${props}`, 'get', null, false);
    console.log('Fetching Sample Plans Data...');
    console.log(results.data);
    return results.data;
  });

  //Keyplans
  static getKeyplansData = _.memoize(async () => {
    const results = await this._callService('/keyplans/', 'get', null, false);
    console.log('Fetching Keyplans Data...');
    console.log(results.data);
    return results.data;
  });

  static getFilteredKeyplansData = _.memoize(async (props) => {
    const results = await this._callService(`/keyplans/?filter[title][_eq]=${props}`, 'get', null, false);
    console.log('Fetching Key Plans Data...');
    console.log(results.data);
    return results.data;
  });

  //Keyplates Filter
  // static getFilteredKeyplatesData = _.memoize(async (props) => {
  //   // console.log('getFloorplansData');
  //   // console.log(`/floorplans/?filter[category][_eq]=${props}`);
  //   const results = await this._callService(`/keyplates/?filter[category][_eq]=${props}`, 'get', null, false);
  //   console.log('Fetching Sample Plans Data...');
  //   console.log(results.data);
  //   return results.data;
  // });

  //Renderings
  static getRenderingsData = _.memoize(async () => {
    const results = await this._callService('/renderings/', 'get', null, false);
    console.log('Fetching Renderings Data...');
    console.log(results.data);
    return results.data;
  });

  //Views 
  static getViewsData = _.memoize(async () => {
    const results = await this._callService('/views/', 'get', null, false);
    console.log('Fetching Views Data...');
    console.log(results.data);
    return results.data;
  });

  static getFilteredViewsData = _.memoize(async (props) => {
    // console.log('getFloorplansData');
    // console.log(`/floorplans/?filter[category][_eq]=${props}`);
    const results = await this._callService(`/views/?filter[title][_eq]=${props}`, 'get', null, false);
    console.log('Fetching Filtered Views Data: '+props);
    console.log(results.data);
    return results.data;
  });

  //Digital Brochure
  static getDigitalBrochureData = _.memoize(async () => {
    const results = await this._callService('/brochure/', 'get', null, false);
    console.log('Fetching Digital Brochure Data...');
    console.log(results.data);
    return results.data;
  });

  //Digital Investor Brochure
  static getDigitalInvestorBrochureData = _.memoize(async () => {
    const results = await this._callService('/investorbrochure/', 'get', null, false);
    console.log('Fetching Digital Investor Brochure Data...');
    console.log(results.data);
    return results.data;
  });

  //Project
  static getProjectData = _.memoize(async () => {
    const results = await this._callService('/projectData/', 'get', null, false);
    console.log('Fetching Project Data...');
    console.log(results.data);
    return results.data;
  });

  //Builder's Story
  static getStoryData = _.memoize(async () => {
    const results = await this._callService('/story', 'get', null, false);
    console.log('Fetching Builders Story Data...');
    console.log(results.data);
    return results.data;
  });

  static getFilteredStoryData = _.memoize(async (props) => {
    // console.log('getFloorplansData');
    // console.log(`/floorplans/?filter[category][_eq]=${props}`);
    const results = await this._callService(`/story/?filter[id][_eq]=${props}`, 'get', null, false);
    console.log('Fetching Filtered Story Data: '+props);
    console.log(results.data);
    return results.data;
  });

  // screensaver
  static getScreensaverData = async () => {
    const results = await this._callService('/screensavers/', 'get', null, false);
    console.log('Fetching Screensaver Data...');
    console.log(results.data);
    return results.data;
  };

  // POST: /custom/email/:email
  static postFavouritesList = async (payload, email, onComplete) => {
    console.log('sending email to: '+email);
    const resp = await this.service.request(
      {
        method: 'POST',
        url: `/email/${email}`,
        data: payload
      }
    )
    onComplete(resp.status);
    console.log('email sent successfully to '+email+ ' status: '+resp.status);
    // console.log(payload);
  }
}

export default apiService;